<template>
  <div class="container">
    <div class="screen">
      <div class="input">
        <div>消息标题：</div>
        <el-input v-model="input" placeholder="输入内容"></el-input>
      </div>
      <div>
        状态：
        <el-select v-model="value" clearable placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        日期：
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          range-separator="至"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <el-button type="primary" @click="getMessageList('nopage')">查询</el-button>
    </div>
    <div class="table">

      <el-table border :cell-style="{ textAlign: 'center' }" @sort-change="sortChange" :header-cell-style="{textAlign: 'center'}" :data="messageList" stripe style="width: 100%">
        <el-table-column type="index" width="100" label="编号"></el-table-column>
        <el-table-column prop="msg_title" label="消息标题"></el-table-column>
        <el-table-column prop="push_date" sortable  width="300" label="发布时间"></el-table-column>
        <el-table-column prop="view_status" width="200" label="状态"></el-table-column>
        <el-table-column  label="详情"  width="200">
          <template slot-scope="scope">
            <el-button @click="getMessageDetail(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background @current-change="curretnChange"
            @prev-click="curretnChange"
            :current-page="page"
            layout="total,sizes, prev, pager, next,jumper"
              @size-change="handleSizeChange"
        :hide-on-single-page="false"
            @next-click="curretnChange"  :total="total"></el-pagination>
    </div>
    <el-dialog :close-on-press-escape="false" :title="messageDetail.msg_title" :visible.sync="dialogVisible" width="50%" >
      <div class="content">
        <!-- <div class="title">{{messageDetail.msg_title}}</div> -->
        <div class="message" v-html="messageDetail.msg_content"></div>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "../store/index"

import { formatDateTime } from '@/utils/util'
export default {
  name: 'message',
  data () {
    return {
      dialogVisible: false,
      value1: [],
      input: '',
      options: [
        {
          value: '0',
          label: '待查看'
        },
        {
          value: '1',
          label: '已查看'
        }
      ],
      value: '',
      messageList: [],
      total: 0,
      order_by: '',
      page: 1,
      limit: 10,
      unViewNum: 0,
      messageDetail: {}

    }
  },
  created () {
    this.getMessageList()
    
  },
  computed: {
    shopInfo () {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    }
  },
  methods: {
    handleSizeChange (e) {
      this.limit = e
      this.getMessageList()
    },
    sortChange (e) {
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.order_by = orderBy + '__desc'
      } else {
        this.order_by = orderBy + '__asc'
      }
      this.getMessageList()
    },
    curretnChange (e) { // 改变页码
      this.page = e
      this.getMessageList()
    },
    getMessageDetail (e) { // 获取消息详情
      // eslint-disable-next-line camelcase
      var msg_id = e.msg_id
    
      this.dialogVisible = true

      var messageList = this.messageList
      this.$http2.post('/merapi/message/view', {  msg_id }).then(res => {
        messageList.forEach(item => {
          // eslint-disable-next-line camelcase
          if (item.msg_id === msg_id) {
            item.view_status = '已查看'
          }
        })
        this.messageList = messageList
        let num = 0
        messageList.forEach(item=>{
          if(item.view_status == "待查看")  {
              num += 1
          }
        })
        store.dispatch('setUnViewMessageNum', num)
      }).catch(err => {
 
        return this.$message.error(err)
      })
      this.$http2.post('/merapi/message/detail', { msg_id }).then(res => {
        this.dialogVisible = true
        this.messageDetail = res.data.data
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    getMessageList (e) { // 获取消息列表

      // eslint-disable-next-line camelcase
      var msg_title = this.input
      // eslint-disable-next-line camelcase
      var view_status = this.value
      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      if (Object.prototype.toString.call(this.value1) === '[object Array]') {
        if (this.value1[0]) {
          // eslint-disable-next-line camelcase
          start_date = formatDateTime(this.value1[0])
        }
        if (this.value1[1]) {
          // eslint-disable-next-line camelcase
          end_date = formatDateTime(this.value1[1])
        }
      }
      this.page = e === 'nopage' ? 1 : this.page
      var page = this.page
      var limit = this.limit
      // eslint-disable-next-line camelcase
      var order_by = this.order_by
      this.$http2.post('/merapi/message/list', { order_by, msg_title, view_status, start_date, limit, page, end_date }).then(res => {
        this.messageList = res.data.data.list
        let num = 0
        res.data.data.list.forEach(item=>{
          if(item.view_status == "待查看")  {
              num += 1
          }
        })

        store.dispatch('setUnViewMessageNum', num)
        this.total = res.data.data.total
      }).catch(err => {
        return this.$message.error(err)
      })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  .screen {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 19px;
    &>div {
      margin-right: 40px;
      color: #222;
      font-size: 16px;
      // font-weight: 600;
    }
    
    .el-button {
        background-color: #0078fb;
    }
    .input {
      display: flex;
      align-items: center;

      div {
        width: 100px;
      }
      .el-input {
        width: 200px;
      }
    }
  }
  .table {
    margin-top: 30px;
  }
 .el-dialog  {
   .el-dialog__header {
    display: flex;
    justify-content: center;
}
    .content {
      // text-align: center;
        .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .message {
        font-size: 18px;
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
  }
}
</style>
